import React from 'react';
import { Fade, Flip } from 'react-reveal';
import { ReactSVG } from 'react-svg';
import RealCard from '../components/RealCard';
import reals from '../data/reals';
import linkedinIcon from '../assets/linkedin.svg';

const Real = () => {
  return (
    <div className="Real">
      <div className="container">
        <Fade top>
          <h2>Mes réalisations</h2>
        </Fade>
        {reals.map((real, i) => (
          <Fade key={real.name} force delay={i * 200}>
            <div style={{ perspective: '500px' }}>
              <RealCard real={real} />
            </div>
          </Fade>
        ))}
      </div>
      <div className="info-container">
        ...et bien d’autres, que je serais ravi de vous présenter
      </div>
      <Flip top cascade>
        <a
          href="https://www.linkedin.com/in/jonathan-luquet-03271812a/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="contact">
            Contactez-moi !
            <ReactSVG src={linkedinIcon} />
          </div>
        </a>
      </Flip>
    </div>
  );
};

export default Real;
