import React from 'react';
import { ReactSVG } from 'react-svg';
import linkedinIcon from '../assets/linkedin.svg';
import oclockIcon from '../assets/oclock.svg';
import logoBanjo from '../assets/logowhite.png';
import { Fade, Flip } from 'react-reveal';
import useMousePosition from '../hooks/useMousePosition';

const Me = () => {
  const mouse = useMousePosition();
  return (
    <div className="Me">
      <div className="container">
        <Fade top>
          <h2>Profil</h2>
        </Fade>
        <p className="bigtext">
          Après 15 ans dans l’immobilier, j’ai décidé en 2020 de prendre un
          virage serré dans ma carrière pour revenir à mes premières amours : le
          développement web.
        </p>
        <h2>Expérience</h2>
        <div>
          <div className="exp-container">
            <div className="title">
              <a
                href="https://banjomeet.com
            "
                target="_blank"
              >
                <img src={logoBanjo} style={{ width: '120px' }} />
              </a>
            </div>
            <div className="date">2021-Aujourd'hui</div>
          </div>
          <div>Fondateur - Développeur</div>
          <div>
            Développement de A à Z d'une application de rencontre web et iOS
          </div>
          <p>Plébicitée par de nombreux médias, dont Clubic et CNET</p>
          <div className="exp-container">
            <div className="title">
              <ReactSVG src={oclockIcon} />
            </div>
            <div className="date">janv-juil 2021</div>
          </div>
          <div>Formation</div>
          <div>Dev Web et Mobile Fullstack Javascript</div>
          <p>Spécialisation REACT</p>
          <div className="exp-container">
            <div className="title">Autodidacte</div>
            <div className="date">depuis toujours</div>
          </div>
          <p>Veille technologique et perfectionnement</p>
          <div className="bonus-container">
            <h2 className="secondary">Connectons-nous</h2>
            <Flip top cascade>
              <a
                href="https://www.linkedin.com/in/jonathan-luquet-03271812a/"
                target="_blank"
                rel="noreferrer"
              >
                <ReactSVG src={linkedinIcon} />
              </a>
            </Flip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Me;
