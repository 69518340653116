import reactIcon from '../assets/react.svg';
import typescriptIcon from '../assets/typescript.svg';
import nodejsIcon from '../assets/nodejs.svg';
import expressIcon from '../assets/express.svg';
import figmaIcon from '../assets/figma.svg';
import adobeIcon from '../assets/adobe.svg';
import reduxIcon from '../assets/redux.svg';
import angularIcon from '../assets/angular.svg';
import nginxIcon from '../assets/nginx.svg';
import postgresIcon from '../assets/postgres.svg';
import strapiIcon from '../assets/strapi.svg';
import wordpressIcon from '../assets/wordpress.svg';
import gitIcon from '../assets/git.svg';
import notionIcon from '../assets/notion.svg';
import agileIcon from '../assets/agile.svg';

const categories = [
  {
    name: 'Front',
    skills: [
      { label: 'React', picture: reactIcon },
      { label: 'Redux', picture: reduxIcon },
      { label: 'Typescript', picture: typescriptIcon },
      { label: 'Angular', picture: angularIcon },
      { label: 'Wordpress', picture: wordpressIcon },
    ],
  },
  {
    name: 'Back',
    skills: [
      { label: 'NodeJs', picture: nodejsIcon },
      { label: 'Express', picture: expressIcon },
      { label: 'PostgreSQL', picture: postgresIcon },
      { label: 'Strapi', picture: strapiIcon },
      { label: 'Nginx', picture: nginxIcon },
    ],
  },
  {
    name: 'Webdesign',
    skills: [
      { label: 'Figma', picture: figmaIcon },
      { label: 'Adobe CS', picture: adobeIcon },
    ],
  },
  {
    name: 'Workflow',
    skills: [
      { label: 'Git', picture: gitIcon },
      { label: 'Agile', picture: agileIcon },
      { label: 'Notion', picture: notionIcon },
    ],
  },
];

export default categories;
