import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Main from './pages/Main';
import Me from './pages/Me';
import Header from './components/Header';
import Fade from 'react-reveal/Fade';
import Real from './pages/Real';
import Footer from './components/Footer';
import Cursor from './components/Cursor';

function App() {
  return (
    <BrowserRouter>
      <Cursor />
      <Switch>
        <Route path="/" exact>
          <Fade>
            <Home />
          </Fade>
        </Route>
        <Route path="/main" exact>
          <Header />
          <Fade>
            <Main />
            <Footer />
          </Fade>
        </Route>
        <Route path="/me" exact>
          <Header />
          <Fade>
            <Me />
            <Footer />
          </Fade>
        </Route>
        <Route path="/real" exact>
          <Header />
          <Fade>
            <Real />
            <Footer />
          </Fade>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
