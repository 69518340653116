import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Nav from '../components/Nav';
import moi from '../assets/moi2.png';

const Header = () => {
  const bubblePhrases = [
    'Salut!',
    'Hello!',
    'Aïe!!',
    'Bienvenue!',
    'Enchanté',
    'Ola!',
    'Bonjour!',
  ];
  const headerOverlayBaseText = 'jonathanluquet.dev';
  const [portraitStyle, setPortraitStyle] = useState({});
  const [bubbleStyle, setBubbleStyle] = useState({});
  const [bubbleText, setBubbleText] = useState('');
  const [headerOverlayText, setHeaderOverlayText] = useState(
    headerOverlayBaseText.repeat(100)
  );

  const handleMouseEnter = () => {
    setPortraitStyle({
      transform: 'translateX(-20px) rotate(5deg) scale(1.2)',
    });
    setBubbleStyle({
      opacity: 1,
    });
    const randBubbleText =
      bubblePhrases[Math.floor(Math.random() * bubblePhrases.length)];
    setBubbleText(randBubbleText);
    setHeaderOverlayText(randBubbleText.repeat(500));
  };
  const handleMouseLeave = () => {
    setPortraitStyle({
      transform: 'translateX(0px)',
    });
    setBubbleStyle({
      opacity: 0,
    });
    setHeaderOverlayText(headerOverlayBaseText.repeat(100));
  };
  return (
    <>
      <div className="Header">
        <div className="Header__overlay">
          <div className="Header__overlay--content">{headerOverlayText}</div>
        </div>
        <div className="Header__background">
          <img src={moi} alt="Jonathan Luquet" style={portraitStyle} />
          <div
            className="Header__background--hoverCatcher"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="Header__bubble" style={bubbleStyle}>
              {bubbleText}
            </div>
          </div>
        </div>
        <Link to="/">
          <h1 className="title">
            <div style={{ marginRight: '-2px' }}>Jonathan</div>
            <div style={{ fontSize: '2.6rem' }}>
              Luquet{' '}
              <span style={{ fontSize: '1.5rem', marginLeft: '-10px' }}>
                .dev
              </span>
            </div>
          </h1>
        </Link>
        <Nav />
      </div>
    </>
  );
};

export default Header;
