import React from 'react';
import NavItem from '../components/NavItem';

const Nav = () => {
  return (
    <nav className="Nav">
      <div className="container">
        <NavItem route="/main" label="Compétences" />
        <NavItem route="/me" label="Profil" />
        <NavItem route="/real" label="Réalisations" />
      </div>
    </nav>
  );
};

export default Nav;
