import React from 'react';
import { Fade } from 'react-reveal';

const Footer = () => {
  return (
    <div className="Footer">
      <Fade>
        Copyright 2021 Jonathan Luquet - Site hébergé par OVH - 2 rue Kellermann
        - 59100 Roubaix - France
      </Fade>
    </div>
  );
};

export default Footer;
