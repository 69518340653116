import React from 'react';
import { ReactSVG } from 'react-svg';
import useMousePosition from '../hooks/useMousePosition';
const Icon = ({ icon, label, rotate, translateForce }) => {
  const mouse = useMousePosition();
  return (
    <div className="Icon">
      <ReactSVG src={icon} />
      <div className="label">{label}</div>
    </div>
  );
};

export default Icon;
