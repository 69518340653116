import React from 'react';
import Icon from '../components/Icon';
import Button from '../components/Button';
import { Fade } from 'react-reveal';
import useMousePosition from '../hooks/useMousePosition';

const RealCard = ({ real }) => {
  const mouse = useMousePosition();

  return (
    <div className="RealCard">
      <div className="top-container">
        <div className="main">
          <img src={real.picture} alt="" />
          <p>{real.content}</p>
        </div>
        <div className="tech">
          {real.techs.map((tech, i) => (
            <Fade key={tech.name} force delay={i * 100}>
              <Icon
                label={tech.label}
                icon={tech.picture}
                translateForce={0.5}
              />
            </Fade>
          ))}
        </div>
      </div>
      <div className="bottom-container">
        <Button label={real.buttonLabel} link={real.buttonUrl} />
      </div>
    </div>
  );
};

export default RealCard;
