import calcimmoLogo from '../assets/calcimmo.png';
import lataupeLogo from '../assets/lataupe.png';
import banjoLogo from '../assets/logodegrade2.png';
import reactIcon from '../assets/react.svg';
import typescriptIcon from '../assets/typescript.svg';
import nodejsIcon from '../assets/nodejs.svg';
import expressIcon from '../assets/express.svg';
import reduxIcon from '../assets/redux.svg';
import angularIcon from '../assets/angular.svg';
import postgresIcon from '../assets/postgres.svg';
import spotifyIcon from '../assets/spotify.svg';
import deezerIcon from '../assets/deezer.svg';
import portfolioLogo from '../assets/portfolio.jpg';
import unesecondeLogo from '../assets/unesecondelogo.png';

const reals = [
  {
    picture: banjoLogo,
    content:
      'Site de rencontre basé sur les affinités musicales. Lancé en février 2022.',
    techs: [
      { label: 'React', picture: reactIcon },
      { label: 'Redux', picture: reduxIcon },
      { label: 'NodeJs', picture: nodejsIcon },
      { label: 'Express', picture: expressIcon },
      { label: 'PostgreSQL', picture: postgresIcon },
      { label: 'Spotify', picture: spotifyIcon },
      { label: 'Deezer', picture: deezerIcon },
    ],
    buttonLabel: 'banjomeet.com',
    buttonUrl: 'https://banjomeet.com',
  },
  {
    picture: unesecondeLogo,
    content: 'Une chanson chaque jour, une seconde pour la deviner.',
    techs: [
      { label: 'React', picture: reactIcon },
      { label: 'NodeJs', picture: nodejsIcon },
      { label: 'Express', picture: expressIcon },
      { label: 'PostgreSQL', picture: postgresIcon },
    ],
    buttonLabel: 'uneseconde.one',
    buttonUrl: 'https://uneseconde.one',
  },
  {
    picture: portfolioLogo,
    content: '100% React <3, 100% fait main avec passion.',
    techs: [{ label: 'React', picture: reactIcon }],
    buttonLabel: 'jonathanluquet.dev',
    buttonUrl: 'https://jonathanluquet.dev',
  },
  {
    picture: calcimmoLogo,
    content:
      'Un calculateur multifonctions pour les amateurs et professionnels de l’immobilier.',
    techs: [
      { label: 'Angular', picture: angularIcon },
      { label: 'Typescript', picture: typescriptIcon },
    ],
    buttonLabel: 'calcimmo.com',
    buttonUrl: 'https://www.calcimmo.com',
  },
  {
    picture: lataupeLogo,
    content:
      'Une plateforme pour trouver des lieux de sorties, et des évènements.',
    techs: [
      { label: 'React', picture: reactIcon },
      { label: 'Redux', picture: reduxIcon },
      { label: 'NodeJs', picture: nodejsIcon },
      { label: 'Express', picture: expressIcon },
      { label: 'PostgreSQL', picture: postgresIcon },
    ],
    buttonLabel: 'Regarder la présentation sur YouTube',
    buttonUrl: 'https://www.youtube.com/watch?v=8kGnW_FgmBg&t=2830s',
  },
];

export default reals;
