import React from 'react';
import useMousePosition from '../hooks/useMousePosition';

const Cursor = () => {
  const mouse = useMousePosition();
  return (
    <>
      {/* <div
        className="cursor"
        style={{
          left: `${mouse.mousePosition.x}px`,
          top: `${mouse.mousePosition.y}px`,
        }}
      ></div> */}
      <div
        className="cursor3"
        style={{
          left: `${mouse.mousePosition.x}px`,
          top: `${mouse.mousePosition.y}px`,
        }}
      ></div>
      <div
        className="cursor2"
        style={{
          right: `${mouse.mousePosition.x}px`,
          bottom: `${mouse.mousePosition.y}px`,
        }}
      ></div>
    </>
  );
};

export default Cursor;
