import React from 'react';
import SkillsCard from '../components/SkillsCard';
import categories from '../data/skills';

import { Fade, Flip } from 'react-reveal';
import Tada from 'react-reveal/Tada';
import useMousePosition from '../hooks/useMousePosition';

const Main = () => {
  const mouse = useMousePosition();

  return (
    <div className="Main">
      <div className="main-container">
        <div className="container">
          <Fade top>
            <h2>Compétences</h2>
          </Fade>
          <div>
            <SkillsCard categories={categories} />
            <div className="bonus-container">
              <Tada cascade>
                <h3>Mais surtout</h3>
              </Tada>
              <Flip top cascade>
                <div className="content">
                  Je suis <em>curieux</em>
                </div>
                <div className="content">
                  et <em>j'apprends vite</em>
                </div>
              </Flip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
