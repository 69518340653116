import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Fade } from 'react-reveal';

const Home = () => {
  const [welcomeIndex, setWelcomeIndex] = useState(0);
  const [style, setStyle] = useState({});
  const history = useHistory();

  useEffect(() => {
    welcomeIndex <= 12 &&
      setTimeout(() => {
        console.log(welcomeIndex);
        if (welcomeIndex === 5) console.log('Bonjour vous !');
        if (welcomeIndex === 9)
          setStyle({
            transform: 'scale(100) rotate(10deg)',
            background: '#0c0a13',
            color: '#0c0a13 !important',
            opacity: 0,
            transition: '1500ms',
          });
        setWelcomeIndex(welcomeIndex + 1);
      }, 500);

    if (welcomeIndex === 12) {
      history.push('/main');
    }
  }, [welcomeIndex, history]);

  return (
    <div className="Home">
      <div style={style}>
        <div className="welcome__container">
          <div className="ta-left">
            <Fade force delay={1000}>
              <div className="welcome__text">Bonjour</div>
            </Fade>
            <Fade force delay={1500}>
              <div className="welcome__text">je suis</div>
            </Fade>
          </div>
          <div className="ta-right">
            <Fade force delay={2000}>
              <div className="welcome__emphasis">
                <div>Jonathan</div>
                <div>Luquet</div>
              </div>
            </Fade>
            <Fade force delay={2500}>
              <div className="welcome__text">Développeur</div>
            </Fade>
            <Fade force delay={3000}>
              <div className="welcome__text">curieux</div>
            </Fade>
            <Fade force delay={3500}>
              <div className="welcome__text">passionné</div>
            </Fade>
            <Fade force delay={4000}>
              <div className="welcome__text">créatif</div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
