import { useEffect, useState } from 'react';

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [mouseMovement, setMouseMovement] = useState({ x: null, y: null });

  useEffect(() => {
    const mouseMoveHandler = (event) => {
      const { pageX, pageY } = event;
      const { movementX, movementY } = event;
      setMousePosition({ x: pageX, y: pageY });
      setMouseMovement({ x: movementX, y: movementY });
    };
    document.addEventListener('mousemove', mouseMoveHandler);
    return () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, []);

  return { mousePosition, mouseMovement };
};

export default useMousePosition;
