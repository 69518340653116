import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const NavItem = ({ route, label }) => {
  const location = useLocation();

  return (
    <NavLink to={route}>
      <div className="NavItem">
        <div className={route === location.pathname ? 'label active' : 'label'}>
          {label}
        </div>
        <div
          className={route === location.pathname ? 'circle active' : 'circle'}
        ></div>
      </div>
    </NavLink>
  );
};

export default NavItem;
