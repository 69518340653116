import React from 'react';
import { Fade } from 'react-reveal';
import Icon from '../components/Icon';

const SkillsCard = ({ categories }) => {
  return (
    <>
      {categories.map((category, i) => (
        <Fade key={category.name} force delay={i * 100}>
          <h3>.{category.name}</h3>
          <div className="icon-container">
            {category.skills.map((skill, i) => (
              <Fade key={skill.label} force delay={i * 200}>
                <Icon
                  icon={skill.picture}
                  label={skill.label}
                  rotate
                  translateForce={2}
                />
              </Fade>
            ))}
          </div>
        </Fade>
      ))}
    </>
  );
};

export default SkillsCard;
