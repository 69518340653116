import React from 'react';

const Button = ({ link, label }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <div className="Button">{label}</div>
    </a>
  );
};

export default Button;
